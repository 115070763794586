import {
  GoodsClassification,
  GuaranteeData,
  NonArrivedInvestigationInfo,
  PreviousDocument,
  SecurityDetails,
  TransitData,
  TransitDeclaration,
  TransitGoodsItem,
  TransitInspectionStatus,
  TransitPhase,
  TransitStatus,
  TransportDetails
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../app-context";
import {ComparatorChain} from '../common/comparator-chain';
import {VisitContext} from '../visit-details/visit-context';
import {nanoId, sendQuery} from "../common/utils";
import moment from "moment";
import {map} from "rxjs/operators";

const transitPhases: TransitPhase[] = [TransitPhase.undeclared, TransitPhase.inspection,
  TransitPhase.insufficientGuarantee, TransitPhase.proofOfArrivalRequested, TransitPhase.declared, TransitPhase.sent,
  TransitPhase.releaseRequested, TransitPhase.cancellationRequested, TransitPhase.rejected, TransitPhase.accepted,
  TransitPhase.inTransit, TransitPhase.proofOfArrivalSent, TransitPhase.arrived, TransitPhase.writtenOff,
  TransitPhase.cancelled];

export const findTransitClassifications = (term: string) =>
  sendQuery("com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications", {term: term})
    .pipe(map((array: GoodsClassification[]) => array.filter((goodsClassification: GoodsClassification) => {
      const length = goodsClassification?.code?.length;

      return length === 6 || length === 8;
    })));

export const transitStatusComparator: ComparatorChain = new ComparatorChain(
  'status.inspectionStatus.inspectionExpiryDate',
  (a: TransitDeclaration, b: TransitDeclaration): number => transitPhases.indexOf(a.status.phase) - transitPhases.indexOf(b.status.phase),
  'data.containerNumber');

export const transitItemComparator: ComparatorChain = transitStatusComparator.reverseProperties();

export function initializeTransit(o: TransitDeclaration = undefined): TransitDeclaration {
  const initializeNew = !o;
  o ||= <TransitDeclaration>{}
  const result = o;
  result.declarantShortName = o.declarantShortName || AppContext.userProfile.organisation?.shortName;
  result.lrn = o.lrn || nanoId();
  result.crn = o.crn || (VisitContext.savedVisit ? VisitContext.savedVisit.crn : null);
  result.data = initializeTransitData(o.data)
  result.dataFromCustoms = initializeTransitData(o.dataFromCustoms)
  result.status = o.status || <TransitStatus>{};
  if (result.status.mrnSent && ["APMRTM", "APMII", "BCW", "RWG", "UNIP"].some(t => t === result.data.dischargeTerminal?.organisationShortName)) {
    //we don't get accepts from these terminals (they're still using the legacy MID 1.0)
    result.status.mrnAccept = result.status.mrnSent;
  }
  result.status.sendDate = initializeNew && result.crn ? null : !result.crn ? moment().format('YYYY-MM-DD') : o.status?.sendDate;
  result.status.sendOnAta = initializeNew && result.crn ? true : o.status?.sendOnAta;
  result.status.inspectionStatus = result.status.inspectionStatus || <TransitInspectionStatus>{};
  result.status.info = result.status.info || <NonArrivedInvestigationInfo>{};
  if (result.data.goodsItems.length === 0) addEmptyGoodsItem(result);
  if (result.data.guarantees.length === 0) addEmptyGuarantee(result);
  return result;
}

export function initializeTransitData(o: TransitData = <TransitData>{}): TransitData {
  const data = o || <TransitData>{};
  data.transitOffices = data.transitOffices || [];
  data.departureTransport = data.departureTransport || <TransportDetails>{};
  data.borderTransport = data.borderTransport || <TransportDetails>{};
  data.securityDetails = data.securityDetails || <SecurityDetails>{};
  data.securityActivated = data.securityActivated || false;
  data.transportTerm = <any>(typeof data.transportTerm == 'string' ? moment.duration(data.transportTerm).asSeconds() : data.transportTerm);
  data.seals = data.seals || [];
  data.itinerary = data.itinerary || [];
  data.goodsItems = data.goodsItems || [];
  data.guarantees = data.guarantees || [];
  removeNationalityIfNotAllowed(data.departureTransport);
  removeNationalityIfNotAllowed(data.borderTransport);
  return data;
}

export function addEmptyGoodsItem(declaration: TransitDeclaration) {
  declaration.data.goodsItems.splice(0, 0, <TransitGoodsItem>{
    previousDocument: <PreviousDocument>{},
    producedDocuments: []
  });
}

export function addEmptyGuarantee(declaration: TransitDeclaration) {
  declaration.data.guarantees.push(<GuaranteeData>{
    type: null,
    country: {},
    customsValue: {
      currency: {}
    }
  });
}

export function nationalityAllowed(transportDetails: TransportDetails) {
  return transportDetails.modeOfTransport !== "RAIL_TRANSPORT";
}

export function removeNationalityIfNotAllowed(transportDetails: TransportDetails) {
  if (!nationalityAllowed(transportDetails)) {
    transportDetails.nationality = null;
  }
}
